import React from 'react';
import EmailIcon from '@mui/icons-material/Email';

const WhatsAppButton = ({ label, onClick }) => {
  return (
    <div
      onClick={onClick}
      style={{ 
        display: 'inline-flex', 
        alignItems: 'center', 
        cursor: 'pointer', 
        padding: '5px', 
        border: '1px solid #009D00', // Optional border for visual distinction
        borderRadius: '5px',// Rounded corners
       
        justifyContent: 'center',
        marginTop: '10px',
        background: '#009D00',
        color: 'white',
      }}
    >
      <EmailIcon style={{ color: 'white', fontSize: '26px' }} />
      <span>{label}</span>
    </div>
    
  );
};

export default WhatsAppButton;
