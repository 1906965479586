import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchColumn.css';

const SearchColumn = () => {
  // State to hold the fetched data
  const [data, setData] = useState({
    categories: [],
    makes: [],
  });

  // State to hold the selected values and reference input
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedMake, setSelectedMake] = useState('');
  const [reference, setReference] = useState('');

  const navigate = useNavigate();

  // Fetch data from the API endpoint when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://gahmotors.co.uk/apiiiwebsite/search_column_cars.php');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setData(jsonData); // Set the data from the API response
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to ensure this runs once on mount

  // Handle category change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // Handle make change
  const handleMakeChange = (event) => {
    setSelectedMake(event.target.value);
  };

  // Handle reference change
  const handleReferenceChange = (event) => {
    setReference(event.target.value);
  };

  // Handle search button click
  const handleSearch = () => {
    if (reference) {
      // Search by reference
      navigate(`/search-by/${reference}`);
    } else if (selectedMake && selectedCategory && selectedCategory !== 'all') {
      // Search by both make and year (category)
      navigate(`/search/${selectedMake}/${selectedCategory}`);
    } else if (selectedMake && selectedCategory === 'all') {
      // Search by make only (when category is 'See all years')
      navigate(`/search/${selectedMake}/all`);
    } else if (selectedMake) {
      // Search by make only (if no category selected)
      navigate(`/search/${selectedMake}/all`);
    } else {
      // Default action if no input
      alert('Please select a make or provide a reference.');
    }
  };

  // Render the fetched data
  return (
    <div className="searchDiv">
      <div className="vertical-stack">
        <div className="form-group">
          <h5>Make:</h5>
          <select className="full-width" value={selectedMake} onChange={handleMakeChange}>
            <option value="">Select make</option>
            <option value="all">See all makes</option>
            {data.makes.map((make) => (
              <option key={make} value={make}>
                {make}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <h5>Year:</h5>
          <select className="full-width" value={selectedCategory} onChange={handleCategoryChange}>
            <option value="">Select year</option>
            <option value="all">See all years</option>
            {data.categories
              .slice() // create a shallow copy of the array to avoid mutating the original array
              .sort((a, b) => b - a) // sort the years in descending order
              .map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
          </select>
        </div>

        <div className="form-group">
          <h5>Search:</h5>
          <input
            className="full-width"
            type="text"
            value={reference}
            onChange={handleReferenceChange}
            style={{ width: '97%' }}
            placeholder="EX. 2018 BMW"
          />
        </div>

        <p align="center">
          <input
            type="submit"
            id="boto_buscador"
            onClick={handleSearch}
            style={{
              backgroundColor: 'black',
              color: 'gold',
              padding: '6px',
              width: '100px',
            }}
            value="SEARCH"
          />
        </p>
      </div>
    </div>
  );
};

export default SearchColumn;
