// src/components/Header.js
import React , { useState }from 'react';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import './headerCss.css';
import PopupMenu from './MenuOpened';



//Iconite
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
//import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MenuIcon from '@mui/icons-material/Menu';



import FacebookIcon from '@mui/icons-material/Facebook';
import configDetails from './../components/utils/ConfigDetails';


const Header = () => {
  
  const { t } = useTranslation(); // Use the `t` function to get translations
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleEmailClick = () => {
    window.location.href = `mailto:${configDetails.email}`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${configDetails.sw}`; // Phone number with country code
  };
  const redirectHome = () => {
    window.location.href = `/`; // Phone number with country code
  };

 
  const redirectFacebook = () => {
    window.location.href = `https://www.facebook.com/profile.php?id=100071149761401`; // Phone number with country code
  };
  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };

  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };
  return (
   
    <AppBar position="sticky" className='headerBarParent' elevation={0} > {/* Transparent and sticky */}
      <Toolbar className='logoCenteredMobile'>
        <img src='../logo.png' alt='logo' onClick={redirectHome}  style={{ marginRight: '10px', width: '10%', cursor:'pointer'}}  className='logoCenteredMobile' id='forlogomobile'/> {/* Space after the logo */}
        <div className='menuDesktop' style={{ flexGrow: 1 }}>
        <Button color="inherit" component={Link} to="/" className="header-button">
          {t('HomePage')} {/* Home */}
        </Button>
        <Button  color="inherit" component={Link} to="/inventory" className="header-button" style={{background:'#FF3232!important'}} >
        {t('machineryList')}
        </Button>
       

        <Button color="inherit" component={Link} to="/services" className="header-button">
          {t('Our Services')} {/* Contact */}
        </Button>

       

        <Button color="inherit" component={Link} to="/about-us" className="header-button">
          {t('About us')} {/* Contact */}
        </Button>

   

        <Button color="inherit" component={Link} to="/contact" className="header-button">
          {t('ContactPage')} {/* Contact */}
        </Button>
        
        <Button color="inherit" component={Link} to="/reviews" className="header-button">
          Reviews {/* Contact */}
        </Button>
      
       
        </div>
        <div className="right-aligned displayNone">
        
        
        {/* <LanguageSwitcher className="displayNone"/> Language switcher */}
        <PhoneIcon style={{color:'#FF3232', fontSize: '26px'}} className='buttonFormOutline marginLeft displayNone'     onClick={handlePhoneClick}/>
        <EmailIcon style={{color:'#FF3232', fontSize: '26px'}} className='buttonFormOutline marginLeft noEmailMobile displayNone'     onClick={handleEmailClick}/>
        
        <FacebookIcon style={{color:'#FF3232', fontSize: '26px'}} label="Facebook" className='buttonFormOutline marginLeft displayNone' onClick={redirectFacebook}>  </FacebookIcon>
        {/* <WhatsAppIcon style={{color:'#FF3232', fontSize: '26px'}} className='buttonFormOutline marginLeft displayNone'  onClick={handleWhatsAppClick}/> */}
        
        </div>

        
      </Toolbar >
      <div className='displayNoneDesktop marginLeft20A'>
     
      
      <EmailIcon style={{color:'#bf9900', fontSize: '26px', display:'none'}} className='buttonFormOutline marginLeft     '     onClick={handleEmailClick}/>
      <MenuIcon className="displayNoneDesktop MenuIconNoDesktop mobile-inline marginRight20 fontSiz40" onClick={handlePopupOpen} />
      <FacebookIcon label="Facebook" className='marginLeft fontSiz40' style={{color:'white', fontSize: '26px'}} onClick={redirectFacebook}>  </FacebookIcon>
      <PopupMenu open={isPopupOpen} onClose={handlePopupClose} />
    </div>
    <div className='displayNoneDesktop marginLeft20A'>
   
    
    <div className="phoneButtonContainer"> {/* CSS class for mobile-specific styling */}
   
      
    </div>


      
     
   </div>
      
    </AppBar>
  );
};

export default Header;
