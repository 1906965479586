// pages/Home.js
import React from 'react';
import Container from '@mui/material/Container';
import FullWidthSlider from '../components/slider';

import HomePageLeft from '../components/HomePageLeft';
import HomePageRight from '../components/HomePageRight';
import ProductCards from '../components/ProductsHomePage';
import '../components/style/main.css';

const isMobile = window.innerWidth <= 768; // Set your breakpoint here (e.g., 768px for mobile)
const Home = () => (
 
  <Container maxWidth={false} style={{ padding: 0, marginTop: '-30px' }}>

    <FullWidthSlider />

    <div className="two-column-layout paddingTop10" >

    <HomePageLeft/>
    <div style={{ maxWidth: isMobile ? '94%' : '73%' , marginTop:''   ,  background: 'tranparent'}}>
    <HomePageRight style={{ maxWidth: isMobile ? '94%' : '74%' ,backgroundColor: 'gold' }} />

    </div>
    
    </div>

    <div className=" paddingTop10" style={{marginLeft: '10px', marginRight: '10px'}}>
    
    <div
          className="titleOverP"
          style={{
            background: '#ff3232', 
            marginBottom: '10px', 
            fontWeight: 'bold',
            padding: '5px', 
            paddingLeft: '15px', 
            textAlign: 'left', 
            textTransform: 'uppercase',
            color:'white',
            marginTop: ' 30px',
           
          }}>
          NEW VEHICLES
      </div>
    <div>
      <ProductCards/>
    </div>
    
    </div>
    
  </Container>
);

export default Home;
