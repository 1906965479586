import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert'; // To display error messages
import './CategoryCards.css';

const CategoryCards = () => {
  const [categories, setCategories] = useState([]); // State to hold fetched data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State for error handling

  const navigate = useNavigate(); // Initialize the useNavigate hook

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('https://gahmotors.co.uk/apiiiwebsite/get_category_counts_test_just_cars.php'); // Fetch from backend

        if (!response.ok) {
          throw new Error('Failed to fetch data'); // Handle unsuccessful responses
        }

        const data = await response.json(); // Parse the JSON response
        if (!Array.isArray(data.makes)) {
          throw new Error('Data format is incorrect'); // Validate the data structure
        }

        // Calculate the total count of all vehicles
        const totalVehiclesCount = data.makes.reduce((sum, make) => sum + parseInt(make.total_count, 10), 0);

        // Manually add the "See all vehicles" option
        const allVehiclesCard = {
          make: 'See all vehicles',
          total_count: totalVehiclesCount, // Correctly calculate the total count
          photo_name: 'all_stock.jpg' // Ensure you have this image
        };

        // Include the "See all vehicles" card at the start of the array
        const sortedCategories = [allVehiclesCard, ...data.makes];

        setCategories(sortedCategories); // Store categories in state
        setLoading(false); // Update loading status
      } catch (err) {
        setError(err.message); // Set error message in case of failure
        setLoading(false); // Stop loading
      }
    };

    fetchCategories(); // Fetch data when the component mounts
  }, []); // Empty dependency array to ensure it runs only once

  if (loading) {
    return <CircularProgress />; // Display a loading spinner during data fetch
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>; // Display an error message if fetching fails
  }

  const handleCardClick = (make) => {
    if (make.make === 'See all vehicles') {
      navigate('/inventory'); // Redirect to a specific route for all vehicles
    } else {
      navigate(`/make/${make.make.toLowerCase()}`); // Convert make to lowercase and redirect
    }
  };

  return (
    <Grid container spacing={3}> {/* Grid layout for arranging cards */}
      {categories.map((make, index) => ( // Map over categories to create cards
        <Grid item xs={12} sm={6} md={3} key={index} className='transformonHoverToBlack'>
          <Card
            sx={{ height: '100%', display: 'flex', flexDirection: 'column', cursor: 'pointer' }}
            onClick={() => handleCardClick(make)}
          > {/* Set consistent height and flex direction */}
            {make.photo_name && ( // Only display CardMedia if a photo is available
              <CardMedia
                component="img" // Render as an image
                height="140" // Set height for the image
                image={`iconite_categorii_2/${make.photo_name}`} // Path or URL to the image
                alt={`${make.make} image`} // Alt text for accessibility
                sx={{ objectFit: 'contain' }} // Ensures the image fills the area without distortion
              />
            )}
            <CardContent className="contentCardFromGreyToBlack" sx={{ flex: 1, textAlign: 'center', textTransform: 'lowercase', background: '#343433', color:'#ff3232', marginTop: '5px' }}> {/* Centered content */}
              <Typography variant="h6" className="contentCardFromGreyToBlack"
                style={{ background: '#343433', color:'#ff3232', textTransform: 'uppercase' }} >
                {make.make} ({make.total_count}) {/* Category name and count */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default CategoryCards;
