import React from 'react';
import PhoneButton from './PhoneButton';
import WhatsAppButton from './WhatsuppButton';
import { useTranslation } from 'react-i18next';
import './StickyButtomButtons.css'; // Ensure proper CSS is imported

const StickyBottomButtons = ({ phoneNumber, whatsappNumber }) => {
  const { t } = useTranslation();

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`; // Initiate a phone call
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${whatsappNumber}`;
  };

  return (
    <div
      style={{
        position: 'fixed', // Keeps the component fixed
        bottom: 0, // Align to the bottom
        left: 0, // Align to the left edge
        right: 0, // Align to the right edge
        backgroundColor: 'black', // Background color
        padding: '10px', // Padding around the buttons
        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.1)', // Subtle shadow for visibility
        display: 'flex', // Flex layout
        justifyContent: 'space-between', // Spacing between buttons
        zIndex: 1000, // Ensures it's above other elements
      }}
    >
      <div style={{ width: '50%', textAlign: 'center' }}> {/* Parent div to ensure alignment */}
        <PhoneButton
          label={t('CallUs')}
          onClick={handlePhoneClick}
          style={{ width: '100%!important' }} // Make the button take 100% width
        />
      </div>

      <div style={{ width: '50%', textAlign: 'center' }}> {/* Another parent div for alignment */}
        <WhatsAppButton
          label="Email us"
          onClick={handleEmailClick}
          style={{ width: '100%' }} // Make the button take 100% width
        />
      </div>
    </div>
  );
};

export default StickyBottomButtons;
