import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import './PopupMenu.css'; // Custom CSS for mobile styling
import { useTranslation } from 'react-i18next';
const PopupMenu = ({ open, onClose }) => {
    const { t } = useTranslation(); // Use the `t` function to get translations
  return (
    <Dialog
      fullScreen // Make the dialog full screen
      open={open} // Control whether it's open
      onClose={onClose} // Handle close event
      PaperProps={{
        style: {
          backgroundColor: 'black', // Optional background color
          padding: '20px', // Optional padding
          color:'gold'
        },
      }}
    >
      <DialogTitle>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" style={{float: 'right'}}>
          <CloseIcon /> {/* Quit button to close the popup */}
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <div className="menu-links" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: '10px', padding: '20px' }}>
  <Button component={Link} to="/" style={{ color: 'gold', padding: '15px 30px', fontSize: '16px' }} onClick={onClose}>
    {t('HomePage')} {/* Home */}
  </Button>
  <Button component={Link} to="/inventory" style={{ color: 'gold', padding: '15px 30px', fontSize: '16px' }} onClick={onClose}>
  {t('machineryList')} {/* Contact */}
  </Button>
  <Button component={Link} to="/services" style={{ color: 'gold', padding: '15px 30px', fontSize: '16px' }} onClick={onClose}>
    {t('Services')} {/* Contact */}
  </Button>
  <Button component={Link} to="/about-us" style={{ color: 'gold', padding: '15px 30px', fontSize: '16px' }} onClick={onClose}>
  {t('About us')} {/* Contact */}
  </Button>
  <Button component={Link} to="/contact" style={{ color: 'gold', padding: '15px 30px', fontSize: '16px' }} onClick={onClose}>
  {t('ContactPage')}{/* Contact */}
  </Button>
  <Button component={Link} to="/reviews" style={{ color: 'gold', padding: '15px 30px', fontSize: '16px' }} onClick={onClose}>
    Reviews {/* Contact */}
  </Button>
 
 
</div>

      </DialogContent>
    </Dialog>
  );
};

export default PopupMenu;
