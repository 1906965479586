import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import HomePageLeft from '../components/HomePageLeft';
import CategoryProducts from './MakeProducts'; // Adjust the import path accordingly
import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import { useTranslation } from 'react-i18next';

const ViewProductsBasedOnMake = () => {
  const { make } = useParams(); // Extract category from URL parameters
  const { t } = useTranslation();

  return (
    <Container maxWidth={false} style={{ marginTop: '70px', paddingLeft: '0px' }}>
      <div className="two-column-layout paddingTop10">
        <div className="column-left">
          <HomePageLeft />
        </div>
        <div className="column-right">
          <div className="vehicle-details">
            <div className="vehicle-specifications" style={{color:'white', background:'#FF3232'}}>{t('Make')}: {make}</div>
            <div>
              <CategoryProducts /> {/* Display products based on the category */}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ViewProductsBasedOnMake;
