import React from 'react';
import './FullWidthSlider.css'; // Import your custom CSS for animations (if needed)
import BusinessStatus from './BusinessStatus'; 

const FullWidthImage = () => {
  return (
    <div style={{ width: '100%', height: 'auto' }} className='sliderOnMobile'>
      {/* Full-Width Image with 50% height */}
      <img
        src="/slider.png"
        alt="Slider"
        style={{ width: '100%', height: '88vh', objectFit: 'cover' }}  // Set the height to 50% of the viewport height
      />

      {/* Display the business status below the image */}
      <BusinessStatus className="openCss"/> 
    </div>
  );
};

export default FullWidthImage;
