import React from 'react';

import Container from '@mui/material/Container';

import HomePageLeft from '../components/HomePageLeft';

import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import { useTranslation} from 'react-i18next';


const Services = () => {

  const { t } = useTranslation();

  return (
    <Container maxWidth={false} style={{ marginTop: '100px', paddingLeft: '0px' }}>
    
        <div className="two-column-layout paddingTop10">
          <div className="column-left">
            <HomePageLeft />
          </div>
          <div className="column-right">
          <div className="vehicle-details">
          <div className="vehicle-specifications" style={{background:'#ff3232', color:'white'}}>{t('Services')}</div>
            <div>
            <img className='nodisplayonmobile' src='./services.jpg' width={'100%'} height={'50%'} alt='Services '> 
            </img>
                <p style={{padding:'10px', textAlign:''}}>
                  
                

<p>We are an internet-based company, offering our services exclusively online. We operate a home delivery-only service, free of any charges. All vehicles come with a 14-day period for verification, allowing you to inspect and ensure the vehicle meets your expectations. During this time, if you are not satisfied, you can return the vehicle for a full refund.
Viewings or inspections prior to delivery are not part of our standard services. However, if you wish to reserve a vehicle for viewing or inspection, a 20% deposit is required. 

The vehicles comes with a 1-year comprehensive warranty.
Additionally, it will be delivered with a new MOT for your peace of mind.
</p>
<p>
Upon purchase, you will receive the following documents:
<ul>
<li>V5 registration document</li>
<li>Keys</li>
<li>Owner's manual</li>
<li>MOT certificates</li>
<li>Signed bill of sale</li>
</ul>
</p>
<p>

<bold>Delivery:</bold> Shipping is free across the United Kingdom and will take a maximum of 3 working days.
</p>

                </p>
            </div>
          </div>
          </div>
        </div>
     
    </Container>
  );
};

export default Services;
