import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Grid } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const GalleryProducts = ({ main_photo, all_photos }) => {
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  // Split the all_photos string into an array and clean the paths
  // eslint-disable-next-line 
  const allPhotosArray = all_photos.split(',').map(photo => photo.replace(/[\[\]"]/g, ''));

  const handleOpen = (index) => {
    setCurrentImage(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setCurrentImage((currentImage + 1) % photos.length);
  };

  const handlePrev = () => {
    setCurrentImage((currentImage + photos.length - 1) % photos.length);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  const photos = [
    { src: main_photo },
    ...allPhotosArray.map(photo => ({
      src: photo, // Direct string value for src
    })),
  ];

  return (
    <div style={{ textAlign: 'left' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <img
            src={main_photo}
            className='mainImageMobil'
            alt="Main"
            onClick={() => handleOpen(0)}
            style={{ cursor: 'pointer', width: '100%', height: 'auto', borderRadius: '2%', border: '1px solid #31313145' }}
          />
        </Grid>
        {allPhotosArray.map((photo, index) => (
          <Grid item xs={4} key={index}>
            <img
              src={photo}
              alt={` ${index + 1}`}
              onClick={() => handleOpen(index + 1)}
              style={{ cursor: 'pointer', width: '100%', height: 'auto', borderRadius: '2%', border: '1px solid #31313145', maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }}
            />
          </Grid>
        ))}
      </Grid>

      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Gallery</DialogTitle>
        <DialogContent {...swipeHandlers} style={{ position: 'relative' }}>
          <IconButton
            onClick={handlePrev}
            style={{
              position: 'absolute',
              top: '50%',
              left: '0',
              transform: 'translateY(-50%)',
              zIndex: 1,
              backgroundColor: 'black',
              
              color: 'white',
            }}
          >
            <ArrowBackIosIcon />
          </IconButton>
          <img
            src={photos[currentImage].src}
            alt={` ${currentImage}`}
            style={{ width: '100%', height: 'auto' }}
          />
          <IconButton
            onClick={handleNext}
            style={{
              position: 'absolute',
              top: '50%',
              right: '0',
              transform: 'translateY(-50%)',
              zIndex: 1,
              backgroundColor: 'black',
              color: 'white',
            }}
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default GalleryProducts;
